import React from 'react';
import { Input, InputProps } from '../input';
import { useModifier } from '../hooks/modifiers';
import './styles.scss';

export interface FieldProps extends InputProps {
  label?: string;
  prefix?: string;
  modifier?: string;
  visible?: boolean;
  elementWhenSelected?: JSX.Element;
}

const Field: React.FC<FieldProps> = ({
  label,
  modifier,
  visible = true,
  ...inputProps
}) => {
  const stylesCN = useModifier('field', modifier, {
    hidden: !visible,
  });

  return (
    <div className={stylesCN}>
      {label && <div className="column col-12 field__label">{label}</div>}
      <div className="column col-12 field__input">
        <Input {...inputProps} modifier={modifier} />
      </div>
    </div>
  );
};

export { Field };
