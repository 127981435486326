import React, { useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Dropdown } from '.';
import { HtmlInputEvent, InputOption } from '../..';
import { DropdownState, useDropdownState } from './hooks/dropdown-state';
import { Icon, Icons, joinModifiers, serialize } from '../../..';

function getSelectedItem(value?: string, items?: InputOption[]) {
  if (value && items && items.length > 0) {
    const i = items.find((i) => i && serialize(i.value) === serialize(value));
    if (i) {
      return i;
    }
    return {
      value,
      description: serialize(value),
    };
  }
  return {
    value: '',
    description: '',
  };
}

export interface InputOptionState {
  selected?: boolean;
  hovering?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DropdownOptionsState extends DropdownState {
  searching?: boolean;
  search?: string;
  searchOption?: InputOption[];
}

const defaultDropdownOptionRender = (
  option: any,
  { selected, hovering }: InputOptionState,
  elementWhenSelected?: JSX.Element,
  modifier?: string
) => (
  <div
    className={cn(
      'dropdown-option',
      {
        'dropdown-option--selected': selected,
        'dropdown-option--hovering': hovering,
      },
      modifier
    )}
  >
    {option.description}
    {selected ? elementWhenSelected : null}
  </div>
);

const isEqual = (value: any) => (selected: any) =>
  serialize(selected) === serialize(value);

interface DropdownOptionsProps {
  modifier?: string;
  disabled?: boolean;
  name?: string;
  value?: string;
  options?: InputOption[];
  elementWhenSelected?: JSX.Element;
  optionRender?: (item: InputOption) => string | JSX.Element;
  onSearch?: (search: string) => Promise<InputOption[] | undefined>;
  onChange?: (ev: HtmlInputEvent) => void;
}

const DropdownOptions: React.FC<DropdownOptionsProps> = ({
  modifier = 'icon-right',
  name,
  value,
  options,
  disabled = false,
  optionRender = defaultDropdownOptionRender,
  onSearch,
  onChange,
  elementWhenSelected,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [state, update] = useDropdownState<DropdownOptionsState>(
    (state, { action, ...change }) => {
      // console.log('>', action, change);
      switch (action) {
        case 'options':
          if (change.options) {
            return {
              ...state,
              options: change.options,
            };
          }
          break;
        case 'focus':
          if (!state.focused) {
            return {
              ...state,
              focused: true,
              search: state.selected && state.selected.description,
              searching: false,
            };
          }
          break;
        case 'blur':
          if (state.focused) {
            return {
              ...state,
              focused: false,
              value:
                (state.selected && state.selected.description) || state.value,
              searching: false,
            };
          }
          break;
        case 'select':
          if (change.option) {
            return {
              ...state,
              focused: false,
              value: serialize(change.option.description),
              searching: false,
              selected: change.option,
              hovering: change.option,
            };
          }
          break;
        case 'hover':
          if (change.option) {
            return {
              ...state,
              focused: true,
              hovering: change.option,
            };
          }
          break;
        case 'search':
          if (change) {
            return {
              ...state,
              focused: true,
              search: serialize(change.search),
              searching: true,
              searchOptions: state.options,
              hovering: undefined,
            };
          }
          break;
        case 'searchOptions':
          if (change) {
            return {
              ...state,
              searchOption: change.options || [],
              hovering: undefined,
            };
          }
      }
      return state;
    },
    {
      focused: false,
      value: getSelectedItem(value, options)?.description,
      options,
    }
  );

  const handleOnChange = useCallback(
    (value: any) => {
      if (onChange) {
        onChange({ target: { value, name } });
      }
    },
    [onChange, name]
  );

  const focus = useCallback(() => {
    update('focus');
    if (onSearch && inputRef.current) {
      inputRef.current.select();
    }
  }, [update, onSearch]);

  const blur = useCallback(() => {
    update('blur');
  }, [update]);

  const select = useCallback(
    (option?: InputOption) => {
      if (option) {
        if (serialize(option) !== serialize(state.selected)) {
          update('select', { option });
          handleOnChange(option.value);
        } else {
          blur();
        }
      }
    },
    [state.selected, handleOnChange, blur, update]
  );

  const hover = useCallback(
    (option?: InputOption) => {
      if (option && serialize(option) !== serialize(state.hovering)) {
        update('hover', { option });
      }
    },
    [state.hovering, update]
  );

  const search = useCallback(
    (value: string) => {
      if (onSearch && value !== undefined) {
        onSearch(serialize(value)).then((searchOptions) => {
          if (searchOptions) {
            update('searchOptions', { options: searchOptions });
          }
        });
      }
    },
    [onSearch, update]
  );

  useEffect(() => {
    update('options', { options });
  }, [options, update]);

  useEffect(() => {
    if (!state.searching) {
      update('select', {
        option: getSelectedItem(value, [
          ...(state.options || []),
          ...(state.searchOption || []),
        ]),
      });
    }
  }, [value, state.searching, state.options, state.searchOption, update]);

  useEffect(() => {
    if (state.focused) {
      const handleKeyDown = (ev: Event) => {
        const options = state.searching ? state.searchOption : state.options;
        if (options && options.length > 0) {
          const keyEvent = ev as KeyboardEvent;
          /* eslint-disable no-case-declarations */
          switch (keyEvent && keyEvent.key) {
            case 'ArrowUp':
              ev.preventDefault();
              if (!state.hovering) {
                hover(options[options.length]);
              } else {
                const index = options.findIndex(isEqual(state.hovering));
                if (options[index - 1]) {
                  hover(options[index - 1]);
                }
              }
              break;
            case 'ArrowDown':
              ev.preventDefault();
              if (!state.hovering) {
                hover(options[0]);
              } else {
                const index = options.findIndex(isEqual(state.hovering));
                if (options[index + 1]) {
                  hover(options[index + 1]);
                }
              }
              break;
            case 'Enter':
              select(state.hovering || state.selected);
              break;
            case 'Tab':
              break;
            case 'Escape':
              blur();
          }
          /* eslint-enable no-case-declarations */
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [
    state.focused,
    state.options,
    state.hovering,
    state.selected,
    state.searchOption,
    select,
    hover,
    blur,
    state.searching,
  ]);

  const handleOnClick = useCallback(
    (option?: InputOption) => () => {
      select(option);
      noBlur.current = false;
      if (inputRef.current) {
        inputRef.current.focus();
      }
      blur();
    },
    [select]
  );

  const searchThrottling = useRef<any>();
  const handleOnHtmlChange = useCallback(
    (ev: HtmlInputEvent) => {
      const value = ev.target.value || '';
      update('search', { search: value });
      if (onSearch) {
        if (searchThrottling.current) {
          clearTimeout(searchThrottling.current);
        }
        searchThrottling.current = setTimeout(() => {
          search(value);
        }, 250);
      }
    },
    [onSearch, search, update]
  );

  const noBlur = useRef(false);
  const controlBlur = useCallback(
    (canBlur: boolean) => () => {
      noBlur.current = canBlur;
    },
    []
  );
  const controledBlur = useCallback(() => {
    if (!noBlur.current) {
      blur();
    }
  }, [blur]);

  const activateSearchInput = useCallback((input: HTMLInputElement) => {
    if (input) {
      input.focus();
      input.select();
    }
  }, []);

  const scrollIntoView = useCallback(
    (selected: boolean) => (div: HTMLDivElement) => {
      if (selected && div) {
        div.scrollIntoView();
      }
    },
    []
  );

  // console.log('DropdownList.render', state.value, {
  //   state: JSON.parse(JSON.stringify({ state })),
  // });

  const searchable = !!onSearch;
  const visibleValue = state.searching ? state.search : state.value;
  const visibleOptions =
    state.searching && serialize(state.search).length > 0
      ? state.searchOption
      : state.options;

  return (
    <>
      <div className="dropdown-input">
        <input
          ref={inputRef}
          type="text"
          value={serialize(visibleValue)}
          disabled={disabled}
          onChange={handleOnHtmlChange}
          onFocus={focus}
          onBlur={controledBlur}
          onClick={focus}
          readOnly={!searchable}
          className="dropdown-input__container--input"
        />
        <div className="dropdown-input__container--icon">
          <Icon icon={Icons.ChevronDown} />
        </div>
      </div>
      {state.focused ? (
        <Dropdown modifier={joinModifiers('list', modifier)} visible>
          <div className="dropdown__wrapper">
            <div className="dropdown__container">
              {searchable ? (
                <div className="dropdown__search">
                  <input
                    ref={activateSearchInput}
                    value={visibleValue}
                    onChange={handleOnHtmlChange}
                    onFocus={focus}
                    onBlur={controledBlur}
                    onClick={focus}
                    onMouseOver={controlBlur(true)}
                    onMouseEnter={controlBlur(true)}
                    onMouseOut={controlBlur(false)}
                  />
                </div>
              ) : null}
              {visibleOptions && visibleOptions.length > 0 ? (
                <div className="dropdown__content">
                  {visibleOptions.map((option, index) => {
                    const s = serialize(option) === serialize(state.selected);
                    const h = serialize(option) === serialize(state.hovering);
                    return (
                      <div
                        ref={scrollIntoView(h)}
                        onClick={handleOnClick(option)}
                        onMouseOver={controlBlur(true)}
                        onMouseEnter={controlBlur(true)}
                        onMouseOut={controlBlur(false)}
                        key={index}
                      >
                        {optionRender(
                          option,
                          { selected: s, hovering: h },
                          elementWhenSelected,
                          modifier
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </Dropdown>
      ) : null}
    </>
  );
};

export default DropdownOptions;
