export function parseModifier(
  prefix: string,
  modifier?: string | (string | undefined)[]
): string | undefined {
  if (modifier && modifier.length > 0) {
    const modifiers = Array.isArray(modifier) ? modifier : modifier.split(' ');
    return modifiers
      .filter((m) => m)
      .map((m) => `${prefix}--${m?.trim()}`)
      .join(' ');
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function joinModifiers(...modifiers: (string | undefined)[]) {
  return modifiers.filter((m) => m).join(' ');
}
