import React from 'react';
import cn from 'classnames';
import { parseModifier } from '../../../helpers/modifier';
import { Floatable } from '../../../floatable';
import DropdownOptions from './options';
import './styles.scss';

export interface DropdownProps {
  modifier?: string;
  visible: boolean;
}

export interface StaticDropdownProps {
  Options: typeof DropdownOptions;
}

export const Dropdown: React.FC<DropdownProps> & StaticDropdownProps = ({
  modifier,
  visible,
  children,
}) => {
  return (
    <div className={cn('dropdown', parseModifier('dropdown', modifier))}>
      <Floatable visible={visible}>{children}</Floatable>
    </div>
  );
};

Dropdown.Options = DropdownOptions;
