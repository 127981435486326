import React, {
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ModifierProps, useModifier } from '../hooks/modifiers';
import { Icon, Icons } from '../icon';
import './styles.scss';

export * from './helper/slide-into-view';

const SLIDE_THRESHOLD = 100;

export interface SlideOptions {
  h?: number;
  v?: number;
}

export interface SliderRefProps {
  slide: (options: SlideOptions) => void;
}

export interface SliderProps extends ModifierProps {
  horizontal?: boolean;
  vertical?: boolean;
  showSlideButtons?: boolean;
  slideThreshold?: number;
  children?: ReactNode;
}

export const Slider = forwardRef<SliderRefProps, SliderProps>(
  ({ children, ...props }, ref: Ref<SliderRefProps>) => {
    // TODO Change this into useStateRef

    const elementRef = useRef<HTMLDivElement>(null);

    const slide = useCallback(({ h = 0, v = 0 }: SlideOptions) => {
      if (elementRef.current) {
        if (elementRef.current.scrollTo) {
          elementRef.current.scrollTo({
            top: elementRef.current.scrollTop + v,
            left: elementRef.current.scrollLeft + h,
            behavior: 'smooth',
          });
        } else {
          elementRef.current.scrollTop += v;
          elementRef.current.scrollLeft += h;
        }
      }
    }, []);

    useImperativeHandle(ref, () => ({ slide }), [slide]);

    const sliderCN = useModifier('slider', props.modifier, {
      'slider--horizontal': props.horizontal,
      'slider--vertical': props.vertical,
    });

    const handleSlide = useCallback(
      (options: SlideOptions) => () => {
        slide(options);
      },
      [slide]
    );

    // TODO Add resize behaviour to calc buttons to display

    const [showBtn, setShowBtn] = useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });

    const calcBtnToShow = useCallback(() => {
      if (props.showSlideButtons && elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const nextShowBtn = {
          top: props.vertical ? elementRef.current.scrollTop > 0 : false,
          left: props.horizontal ? elementRef.current.scrollLeft > 0 : false,
          bottom: props.vertical
            ? rect.height <
              elementRef.current.scrollHeight - elementRef.current.scrollTop
            : false,
          right: props.horizontal
            ? rect.width <
              Math.floor(
                elementRef.current.scrollWidth - elementRef.current.scrollLeft
              )
            : false,
        };
        setShowBtn(nextShowBtn);
      }
    }, [props.showSlideButtons, props.horizontal, props.vertical]);

    const scrollTimer = useRef<any>();

    useEffect(() => {
      calcBtnToShow();
      if (elementRef.current) {
        const element = elementRef.current;
        const handleScroll = () => {
          if (scrollTimer.current) {
            clearTimeout(scrollTimer.current);
          }
          scrollTimer.current = setTimeout(calcBtnToShow, 100);
        };
        element.addEventListener('scroll', handleScroll);
        return () => element.removeEventListener('scroll', handleScroll);
      }
    }, [calcBtnToShow]);

    return (
      <div className={sliderCN}>
        <div ref={elementRef} className="slider__content">
          {children}
        </div>
        {showBtn.top ? (
          <div
            className="slider__btn slider__top-btn"
            onClick={handleSlide({
              v: -(props.slideThreshold || SLIDE_THRESHOLD),
            })}
          >
            <Icon icon={Icons.ChevronLeft} />
          </div>
        ) : null}
        {showBtn.left ? (
          <div
            className="slider__btn slider__left-btn"
            onClick={handleSlide({
              h: -(props.slideThreshold || SLIDE_THRESHOLD),
            })}
          >
            <Icon icon={Icons.ChevronLeft} />
          </div>
        ) : null}
        {showBtn.bottom ? (
          <div
            className="slider__btn slider__bottom-btn"
            onClick={handleSlide({
              v: props.slideThreshold || SLIDE_THRESHOLD,
            })}
          >
            <Icon icon={Icons.ChevronLeft} />
          </div>
        ) : null}
        {showBtn.right ? (
          <div
            className="slider__btn slider__right-btn"
            onClick={handleSlide({
              h: props.slideThreshold || SLIDE_THRESHOLD,
            })}
          >
            <Icon icon={Icons.ChevronRight} />
          </div>
        ) : null}
      </div>
    );
  }
);
