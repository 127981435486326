import React from 'react';
import { ModifierProps, useModifier } from '../hooks';
import './styles.scss';

export interface FlexProps extends ModifierProps {}

export const Flex: React.FC<FlexProps> = ({ modifier, children }) => {
  const stylesCN = useModifier('flex', modifier);
  return <div className={stylesCN}>{children}</div>;
};
