import React, { useCallback } from 'react';
import { SocialNetworkingTypes, socialNetworkingUrl } from './helper';

interface SocialSharingProps {
  link: string;
  type: SocialNetworkingTypes;
  subject?: string;
  message?: string;
}

const width = 600;
const height = 600;

const SocialSharing: React.FC<SocialSharingProps> = ({
  children,
  link,
  subject,
  message,
  type,
}) => {
  const openPopup = useCallback(() => {
    if (type !== 'mail') {
      const left = screen.width / 2 - width / 2;
      const top = screen.height / 2 - height / 2;
      window.open(
        '',
        'popup',
        `width=${width},height=${height},top=${top},left=${left}`
      );
    } else {
      const left = screen.width / 2 - width / 2;
      const top = screen.height / 2 - height / 2;
      const openedWindow = window.open(
        socialNetworkingUrl[type](window.location.href, message, subject),
        'popup',
        `width=${width},height=${height},top=${top},left=${left}`
      );
      setTimeout(() => {
        openedWindow?.close();
      }, 1000);
    }
  }, []);

  return (
    <a
      href={socialNetworkingUrl[type](link, message)}
      target="popup"
      onClick={openPopup}
    >
      {children}
    </a>
  );
};

export { SocialSharing };
