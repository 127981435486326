/* eslint-disable no-case-declarations */
import React, { KeyboardEvent, useCallback } from 'react';
import { serialize } from '../helpers';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown } from './components/dropdown';
import { useModifier } from '../hooks';
import { Flex } from '../flex';
import './styles.scss';

type InputTypes =
  | 'text'
  | 'number'
  | 'date'
  | 'time'
  | 'email'
  | 'select'
  | 'autocomplete'
  | 'radio'
  | 'checkbox'
  | 'checkbox-group'
  | 'textarea'
  | 'custom';

export interface InputEvent {
  type: InputTypes;
  name?: string;
  value?: any;
  isValid: boolean;
}

export interface HtmlInputEvent {
  target: {
    value?: any;
    name?: string;
  };
}

export interface InputOption {
  value: any;
  description: string;
}

const defaultFormater = (value: string) => value;

export interface GetInputProps {
  modifier?: string;
  type: InputTypes;
  name?: string;
  prefix?: any;
  suffix?: any;
  value?: any;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  max?: string;
  min?: string;
  options?: InputOption[];
  optionRender?: (option: InputOption) => string | JSX.Element;
  autocomplete?: (search: string) => Promise<InputOption[] | undefined>;
  validation?: (value: any) => boolean;
  onChange?: (event: InputEvent | HtmlInputEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
  formater?: (value: string) => any;
  elementWhenSelected?: JSX.Element;
  custom?: React.ComponentType<any>;
  extraProps: any;
}

function getInput({
  type,
  name,
  value,
  placeholder,
  disabled,
  options,
  autocomplete,
  optionRender,
  max,
  min,
  onChange,
  onKeyUp,
  elementWhenSelected,
  custom,
  modifier,
  extraProps,
}: GetInputProps) {
  switch (type) {
    case 'text':
    case 'number':
    case 'time':
    case 'email':
    case 'date':
      const extraDateProps = type === 'date' ? { min, max } : {};
      return (
        <input
          type={type}
          value={value}
          disabled={disabled}
          onChange={onChange}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          {...extraDateProps}
        />
      );
    /*case 'select':
        return (
          <select
            value={value}
            disabled={disabled}
            onChange={onChange}
          >
            {options && options.length > 0
              ? options.map((o, i) => (
                  <option key={i} value={o.value}>
                    {o.description}
                  </option>
                ))
              : null}
          </select>
        );*/
    case 'select':
    case 'autocomplete':
      const extraAutocompleteProps =
        type === 'autocomplete' ? { onSearch: autocomplete } : {};
      return (
        <Dropdown.Options
          name={name}
          value={value}
          options={options}
          optionRender={optionRender}
          disabled={disabled}
          onChange={onChange}
          elementWhenSelected={elementWhenSelected}
          modifier={modifier}
          {...extraAutocompleteProps}
        />
      );
    /**/
    case 'radio':
    case 'checkbox-group':
      const linkedName = uuidv4();
      return options && options.length > 0
        ? options.map((o, i) => (
            <div key={i}>
              <label>
                <Flex modifier="v-center">
                  <input
                    type={type === 'checkbox-group' ? 'checkbox' : 'radio'}
                    name={linkedName}
                    value={o.value}
                    disabled={disabled}
                    onChange={onChange}
                    checked={o.value === value}
                  />
                  <span className="input__rc-label">{o.description}</span>
                </Flex>
              </label>
            </div>
          ))
        : null;
    case 'checkbox':
      return (
        <label>
          <Flex modifier="v-center">
            <input
              type={type}
              name={name}
              disabled={disabled}
              onChange={onChange}
              checked={serialize(value) === 'true'}
            />
            <span className="input__rc-label">{name}</span>
          </Flex>
        </label>
      );
    case 'textarea':
      return (
        <textarea
          value={value}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
        />
      );
    case 'custom':
      if (custom) {
        const CustomInput = custom;
        const props = {
          type,
          name,
          value,
          placeholder,
          disabled,
          options,
          autocomplete,
          optionRender,
          max,
          min,
          onChange,
          onKeyUp,
          elementWhenSelected,
          modifier,
          ...extraProps,
        };
        return <CustomInput {...props} />;
      }
      return <div>No Input</div>;
    default:
      return null;
  }
}

export interface InputProps {
  modifier?: string;
  type: InputTypes;
  name?: string;
  prefix?: any;
  suffix?: any;
  value?: any;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  max?: string;
  min?: string;
  options?: InputOption[];
  optionRender?: (option: InputOption) => string | JSX.Element;
  autocomplete?: (search: string) => Promise<InputOption[] | undefined>;
  validation?: (value: any) => boolean;
  onChange?: (event: InputEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
  formater?: (value: string) => any;
  elementWhenSelected?: JSX.Element;
  custom?: React.ComponentType<any>;
}

const Input: React.FC<InputProps> = React.memo<InputProps>(
  ({
    modifier,
    type,
    name,
    prefix,
    suffix,
    value,
    readOnly,
    placeholder,
    disabled = false,
    required = false,
    options,
    autocomplete,
    optionRender,
    max,
    min,
    onChange,
    onKeyUp,
    validation = () => true,
    formater = defaultFormater,
    elementWhenSelected,
    custom,
    ...extraProps
  }) => {
    const inputCN = useModifier(
      'input',
      modifier,
      {
        'input--read-only': readOnly,
        'input--required-filled': required && serialize(value),
        'input--required-unfilled': required && !serialize(value),
      },
      `input--${type}`
    );

    const handleOnChange = useCallback(
      (lastValue: any) => (event: any) => {
        if (onChange) {
          const eventValue = event?.value || event?.target?.value;
          const value = formater(eventValue);
          const isValid = validation ? validation(value) : true;
          onChange({
            type,
            name,
            value: isValid ? value : lastValue,
            isValid,
          } as InputEvent);
        }
      },
      [onChange, formater, validation, type, name]
    );

    return (
      <div className={inputCN}>
        <div className="columns">
          {prefix ? (
            <div className="column col-auto input__prefix">{prefix}</div>
          ) : null}
          <div className="column col-grow">
            {readOnly ? (
              <input type={type} value={serialize(value) || '-'} readOnly />
            ) : (
              getInput({
                type,
                name,
                value: serialize(value),
                placeholder,
                disabled,
                options,
                autocomplete,
                optionRender,
                max,
                min,
                onChange: handleOnChange(value),
                onKeyUp,
                elementWhenSelected,
                modifier,
                custom,
                extraProps,
              })
            )}
          </div>
          {suffix ? (
            <div className="column col-auto input__suffix">{suffix}</div>
          ) : null}
        </div>
      </div>
    );
  }
);

export { Input };
export * from './helpers';

/* eslint-enable no-case-declarations */
