import React from 'react';
import { ModifierProps, useModifier } from '../hooks';
import { Icons } from './icons';
import './styles.scss';

export { Icons } from './icons';

export interface IconProps extends ModifierProps {
  icon: Icons;
}

const Icon: React.FC<IconProps> = ({ modifier, icon }) => {
  const iconCN = useModifier('icon', modifier, `icon-${icon}`, `test1`);
  return <i className={iconCN} />;
};

export { Icon };
