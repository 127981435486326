import React, { useCallback, useRef, useState } from 'react';

import { ModifierProps } from '../hooks';
import { Button } from '../button';
import './styles.scss';
import { Icon, Icons } from '../icon';

interface CarouselProps extends ModifierProps {
  numberOfItems: number;
}
const Carousel: React.FC<CarouselProps> = ({ numberOfItems, children }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const throttle = useRef<any>();

  const [state, setState] = useState({
    isRightEnabled: true,
    isLeftEnabled: false,
  });

  const navigate = useCallback(
    (direction: 'left' | 'right') => () => {
      elementRef.current?.scrollBy({
        behavior: 'smooth',
        left: direction === 'left' ? -200 : 200,
      });
    },
    []
  );

  const onScroll = useCallback(() => {
    if (throttle.current) {
      clearTimeout(throttle.current);
    }
    throttle.current = setTimeout(() => {
      setState({
        isRightEnabled:
          elementRef.current !== null &&
          elementRef.current.scrollLeft <
            numberOfItems * 270 - elementRef?.current?.clientWidth,
        isLeftEnabled: elementRef.current?.scrollLeft !== 0,
      });
    }, 150);
  }, [numberOfItems]);

  return (
    <div className="carousel">
      <div className="carousel__action">
        <Button
          modifier="transparent"
          label={<Icon icon={Icons.CarouselArrowLeft} modifier="brown" />}
          onClick={navigate('left')}
          disabled={!state.isLeftEnabled}
        />
        <Button
          modifier="transparent"
          label={<Icon icon={Icons.CarouselArrowRight} modifier="brown" />}
          onClick={navigate('right')}
          disabled={!state.isRightEnabled}
        />
      </div>
      <div
        className="columns carousel__content"
        ref={elementRef}
        onScroll={onScroll}
      >
        {children}
      </div>
    </div>
  );
};

export { Carousel };
