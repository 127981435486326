export const socialNetworkingUrl = {
  twitter: (link: string, message?: string): string =>
    `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
      message || ''
    )}&url=${encodeURIComponent(link)}`,
  facebook: (link: string): string =>
    `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`,
  linkedin: (link: string): string =>
    `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      link
    )}`,
  mail: (link: string, body?: string, subject?: string): string =>
    `mailto:?${
      subject ? `subject=${encodeURIComponent(subject || '')}&` : ''
    }body=${encodeURIComponent((body && `${body}\n\n${link}`) || link)}`,
  google: (link: string): string =>
    `https://plus.google.com/share?url=${encodeURIComponent(link)}`,
  tumblr: (link: string): string =>
    `http://tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(
      link
    )}`,
  reddit: (link: string, message?: string): string =>
    `https://reddit.com/submit/?url=${encodeURIComponent(
      link
    )}&title=${encodeURIComponent(message || '')}`,
  whatsapp: (link: string, message?: string): string =>
    `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message || ''
    )}%20${encodeURIComponent(link)}`,
  vkontakt: (link: string, message?: string): string =>
    `http://vk.com/share.php?url=${encodeURIComponent(link)}`,
  weibo: (link: string, message?: string): string =>
    `http://service.weibo.com/share/share.php?url=${encodeURIComponent(link)}`,
  baidu: (link: string, message?: string): string =>
    `http://tieba.baidu.com/f/commit/share/openShareApi?url=${encodeURIComponent(
      link
    )}`,
  qzone: (link: string, message?: string): string =>
    `http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(
      link
    )}`,
  douban: (link: string, message?: string): string =>
    `http://shuo.douban.com/!service/share?url=${encodeURIComponent(link)}`,
  telegram: (link: string, message?: string): string =>
    `https://telegram.me/share/url?text=${encodeURIComponent(
      message || ''
    )}&url=${encodeURIComponent(link)}`,
  javascript: (link: string, message?: string): string =>
    `javascript:${encodeURIComponent(link || '')}`,
};

export type SocialNetworkingTypes = keyof typeof socialNetworkingUrl;
