import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import './styles.scss';

export interface Tab {
  label: string | JSX.Element;
  content: string | JSX.Element;
}

export interface TabsProps {
  tabs: Tab[];
}

interface StaticTabsProps {
  Label: typeof TabLabel;
}

const Tabs: React.FC<TabsProps> & StaticTabsProps = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleSetActiveTab = useCallback(
    (activeTab: number) => () => {
      setActiveTab(activeTab);
    },
    []
  );

  return (
    <div className="tabs">
      <div className="tabs__header">
        <ul className="columns">
          {renderTabs(tabs, activeTab, handleSetActiveTab)}
        </ul>
      </div>
      <div className="tabs__active-tab">
        <div className="tabs__content">{renderActiveTab(tabs, activeTab)}</div>
      </div>
    </div>
  );
};

function renderTabs(
  tabs: Tab[],
  activeTab: number,
  onActivateTab: (activeTab: number) => () => void
) {
  return tabs.map((tab, index) => (
    <li className="column col-auto" key={index}>
      <div
        className={cn('tab', { 'tab--active': activeTab === index })}
        onClick={onActivateTab(index)}
      >
        {tab.label}
      </div>
    </li>
  ));
}

function renderActiveTab(tabs: Tab[], activeTab: number) {
  return tabs[activeTab].content;
}

interface TabLabelProps {
  label?: string;
}

const TabLabel: React.FC<TabLabelProps> = ({ label, children }) => {
  return <>{label || children}</>;
};

Tabs.Label = TabLabel;

export { Tabs };
