import React, { useCallback, useState } from 'react';
import { ModifierProps, useModifier } from '../hooks';
import { Button } from '../button';
import { Icon, Icons } from '../icon';
import './styles.scss';

interface ExpandableProps extends ModifierProps {
  expanded?: boolean;
  showBtnExpand?: boolean;
  header: JSX.Element;
}

export const Expandable: React.FC<ExpandableProps> = ({
  children,
  expanded,
  showBtnExpand = true,
  modifier,
  header,
}) => {
  const [expand, setExpand] = useState(expanded ? expanded : false);
  const handlerToggleExpand = useCallback(() => {
    setExpand(!expand);
  }, [expand]);

  const stylesCN = useModifier('expandable', modifier, {
    'expandable--expanded': expand,
  });

  return (
    <div className={stylesCN}>
      <div className="expandable__header" onClick={handlerToggleExpand}>
        {header}
        {showBtnExpand ? (
          <div className="expandable__header-btn">
            <Button
              label={
                <Icon icon={expand ? Icons.ChevronUp : Icons.ChevronDown} />
              }
              onClick={handlerToggleExpand}
              modifier={modifier}
            />
          </div>
        ) : null}
      </div>
      <div className="expandable__content">{children}</div>
    </div>
  );
};
