export type IconsId =
  | 'all_filter'
  | 'apps'
  | 'arrow_right'
  | 'baidu'
  | 'bookmark'
  | 'bullet'
  | 'carousel-arrow-left'
  | 'carousel-arrow-right'
  | 'check'
  | 'chevron_down'
  | 'chevron_left'
  | 'chevron_right'
  | 'chevron_up'
  | 'close'
  | 'docs_filter'
  | 'douban'
  | 'download'
  | 'email'
  | 'excel'
  | 'expand_less'
  | 'expand_more'
  | 'expand'
  | 'facebook'
  | 'favorite'
  | 'filters'
  | 'info'
  | 'instagram'
  | 'linkedin'
  | 'load'
  | 'lock'
  | 'magnifier'
  | 'map_filter'
  | 'menu'
  | 'other_filter'
  | 'pdf'
  | 'plus'
  | 'print'
  | 'qzone'
  | 'rss'
  | 'search'
  | 'share-icon'
  | 'share'
  | 'twitter'
  | 'unexpand'
  | 'vkontakt'
  | 'web_filter'
  | 'weibo'
  | 'youtube';

export type IconsKey =
  | 'AllFilter'
  | 'Apps'
  | 'ArrowRight'
  | 'Baidu'
  | 'Bookmark'
  | 'Bullet'
  | 'CarouselArrowLeft'
  | 'CarouselArrowRight'
  | 'Check'
  | 'ChevronDown'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'ChevronUp'
  | 'Close'
  | 'DocsFilter'
  | 'Douban'
  | 'Download'
  | 'Email'
  | 'Excel'
  | 'ExpandLess'
  | 'ExpandMore'
  | 'Expand'
  | 'Facebook'
  | 'Favorite'
  | 'Filters'
  | 'Info'
  | 'Instagram'
  | 'Linkedin'
  | 'Load'
  | 'Lock'
  | 'Magnifier'
  | 'MapFilter'
  | 'Menu'
  | 'OtherFilter'
  | 'Pdf'
  | 'Plus'
  | 'Print'
  | 'Qzone'
  | 'Rss'
  | 'Search'
  | 'ShareIcon'
  | 'Share'
  | 'Twitter'
  | 'Unexpand'
  | 'Vkontakt'
  | 'WebFilter'
  | 'Weibo'
  | 'Youtube';

export enum Icons {
  AllFilter = 'all_filter',
  Apps = 'apps',
  ArrowRight = 'arrow_right',
  Baidu = 'baidu',
  Bookmark = 'bookmark',
  Bullet = 'bullet',
  CarouselArrowLeft = 'carousel-arrow-left',
  CarouselArrowRight = 'carousel-arrow-right',
  Check = 'check',
  ChevronDown = 'chevron_down',
  ChevronLeft = 'chevron_left',
  ChevronRight = 'chevron_right',
  ChevronUp = 'chevron_up',
  Close = 'close',
  DocsFilter = 'docs_filter',
  Douban = 'douban',
  Download = 'download',
  Email = 'email',
  Excel = 'excel',
  ExpandLess = 'expand_less',
  ExpandMore = 'expand_more',
  Expand = 'expand',
  Facebook = 'facebook',
  Favorite = 'favorite',
  Filters = 'filters',
  Info = 'info',
  Instagram = 'instagram',
  Linkedin = 'linkedin',
  Load = 'load',
  Lock = 'lock',
  Magnifier = 'magnifier',
  MapFilter = 'map_filter',
  Menu = 'menu',
  OtherFilter = 'other_filter',
  Pdf = 'pdf',
  Plus = 'plus',
  Print = 'print',
  Qzone = 'qzone',
  Rss = 'rss',
  Search = 'search',
  ShareIcon = 'share-icon',
  Share = 'share',
  Twitter = 'twitter',
  Unexpand = 'unexpand',
  Vkontakt = 'vkontakt',
  WebFilter = 'web_filter',
  Weibo = 'weibo',
  Youtube = 'youtube',
}

export const ICONS_CODEPOINTS: { [key in Icons]: string } = {
  [Icons.AllFilter]: '61697',
  [Icons.Apps]: '61698',
  [Icons.ArrowRight]: '61699',
  [Icons.Baidu]: '61700',
  [Icons.Bookmark]: '61701',
  [Icons.Bullet]: '61702',
  [Icons.CarouselArrowLeft]: '61703',
  [Icons.CarouselArrowRight]: '61704',
  [Icons.Check]: '61705',
  [Icons.ChevronDown]: '61706',
  [Icons.ChevronLeft]: '61707',
  [Icons.ChevronRight]: '61708',
  [Icons.ChevronUp]: '61709',
  [Icons.Close]: '61710',
  [Icons.DocsFilter]: '61711',
  [Icons.Douban]: '61712',
  [Icons.Download]: '61713',
  [Icons.Email]: '61714',
  [Icons.Excel]: '61715',
  [Icons.ExpandLess]: '61716',
  [Icons.ExpandMore]: '61717',
  [Icons.Expand]: '61718',
  [Icons.Facebook]: '61719',
  [Icons.Favorite]: '61720',
  [Icons.Filters]: '61721',
  [Icons.Info]: '61722',
  [Icons.Instagram]: '61723',
  [Icons.Linkedin]: '61724',
  [Icons.Load]: '61725',
  [Icons.Lock]: '61726',
  [Icons.Magnifier]: '61727',
  [Icons.MapFilter]: '61728',
  [Icons.Menu]: '61729',
  [Icons.OtherFilter]: '61730',
  [Icons.Pdf]: '61731',
  [Icons.Plus]: '61732',
  [Icons.Print]: '61733',
  [Icons.Qzone]: '61734',
  [Icons.Rss]: '61735',
  [Icons.Search]: '61736',
  [Icons.ShareIcon]: '61737',
  [Icons.Share]: '61738',
  [Icons.Twitter]: '61739',
  [Icons.Unexpand]: '61740',
  [Icons.Vkontakt]: '61741',
  [Icons.WebFilter]: '61742',
  [Icons.Weibo]: '61743',
  [Icons.Youtube]: '61744',
};
