import { Context } from '..';

/*
export function mergeState(state: Context, value: Context): Context {
  for (const key in value) {
    if (Object(value[key]) === value[key]) {
      if (Object(state[key]) === state[key]) {
        state[key] = mergeState(state[key], value[key]);
        if (Array.isArray(state[key])) {
          state[key].length = value[key].length;
        }
        continue;
      }
    }
    state[key] = value[key];
    if (Array.isArray(state[key])) {
      state[key].length = value[key].length;
    }
  }
  return state;
}
*/

export function mergeState(state: Context, value: Context): Context {
  // console.log("merge state", value)
  for (const key in value) {
    if (Object(value[key]) === value[key]) {
      if (
        Array.isArray(value[key]) &&
        Array.isArray(state[key]) &&
        value[key].length === state[key].length
      ) {
        state[key] = mergeState(state[key], value[key]);
        continue;
      }
      if (Object(state[key]) === state[key] && !Array.isArray(value[key])) {
        state[key] = mergeState(state[key], value[key]);
        continue;
      }
    }
    state[key] = value[key];
  }
  return state;
}
