import React from 'react';
import { useBindPosition, useModifier } from '../hooks';
import './styles.scss';

export interface FloatableEvent {
  type: 'scroll' | 'resize';
  direction?: 'vertical' | 'horizontal';
}

export interface FloatableProps {
  modifier?: string;
  visible: boolean;
  onEvent?: (ev: FloatableEvent) => void;
  constraint?: ('top' | 'left' | 'bottom' | 'right' | 'width' | 'height')[];
}

const Floatable: React.FC<FloatableProps> = ({
  modifier,
  visible = false,
  onEvent,
  children,
  constraint = ['top', 'left'],
}) => {
  const stylesCN = useModifier('floatable', modifier);

  const [positionRef] = useBindPosition({
    onActive: (target) => {
      const parent = target.parentElement;
      if (parent) {
        const prect: DOMRect = parent.getBoundingClientRect();
        if (constraint.includes('top')) {
          target.style.top = `${prect.top}px`;
        }
        if (constraint.includes('left')) {
          target.style.left = `${prect.left}px`;
        }
        if (constraint.includes('bottom')) {
          target.style.bottom = `${prect.bottom}px`;
        }
        if (constraint.includes('right')) {
          target.style.right = `${prect.right}px`;
        }
        if (constraint.includes('width')) {
          target.style.width = `${prect.width}px`;
        }
        if (constraint.includes('height')) {
          target.style.height = `${prect.height}px`;
        }
      }
    },
    onScroll: (target) => {
      const parent = target.parentElement;
      if (parent) {
        const trect: DOMRect = target.getBoundingClientRect();
        const prect: DOMRect = parent.getBoundingClientRect();
        target.style.top = `${prect.top}px`;
        target.style.left = `${prect.left}px`;
        if (onEvent) {
          if (trect.left !== prect.left) {
            onEvent({
              type: 'scroll',
              direction: 'horizontal',
            });
          }
          if (trect.top !== prect.top) {
            onEvent({
              type: 'scroll',
              direction: 'vertical',
            });
          }
        }
      }
    },
    onResize: () => {
      if (onEvent) {
        onEvent({
          type: 'resize',
        });
      }
    },
  });

  return (
    <div className={stylesCN}>
      {visible ? (
        <div ref={positionRef} className="floatable__container">
          {children}
        </div>
      ) : null}
    </div>
  );
};

export { Floatable };
