import React, { useCallback } from 'react';
import { useModifier } from '../hooks/modifiers';
import './styles.scss';

export interface ButtonProps {
  label?: string | JSX.Element;
  prefix?: string | JSX.Element;
  suffix?: string | JSX.Element;
  modifier?: string;
  primary?: boolean;
  secondary?: boolean;
  borderRadius?: boolean;
  disabled?: boolean;
  active?: boolean;
  style?: any;
  onClick: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  prefix,
  suffix,
  modifier,
  primary,
  secondary,
  onClick,
  disabled,
  active,
  style,
  children,
}) => {
  const stylesCN = useModifier('button', modifier, {
    'button--active': active,
    'button--primary': primary,
    'button--secondary': secondary,
    'button--disabled': disabled,
  });

  const scrollIntoView = useCallback(
    (active?: boolean) => (element?: HTMLButtonElement | null) => {
      if (element && active) {
        element.scrollIntoView();
      }
    },
    []
  );

  return (
    <button
      className={stylesCN}
      ref={scrollIntoView(active)}
      type="button"
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {prefix ? (
        <span className="button__prefix-container">{prefix}</span>
      ) : null}
      {label ? <span className="button__label"> {label} </span> : null}
      {suffix ? (
        <span className="button__suffix-container">{suffix}</span>
      ) : null}
      {children}
    </button>
  );
};
