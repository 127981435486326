import cn from 'classnames';
import { useMemo } from 'react';
import { parseModifier } from '../helpers/modifier';

export interface ModifierProps {
  modifier?: string;
}

export function useModifier(
  element: string,
  modifiers?: string | (string | undefined)[],
  ...args: any[]
) {
  const styles = useMemo(
    () => cn(element, parseModifier(element, modifiers), ...args),
    [args, element, modifiers]
  );
  return styles || '';
}
