import { useCallback, useReducer } from 'react';
import { InputOption } from '../../..';

export interface DropdownState {
  focused: boolean;
  value?: string;
  options?: InputOption[];
  selected?: InputOption;
  hovering?: InputOption;
}

interface DropdownAction {
  action: string;
  [key: string]: any;
}

export type DropdownDispatch = React.Dispatch<DropdownAction>;
export type DropdownReducer<T extends DropdownState> = React.Reducer<
  T,
  DropdownAction
>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDropdownState<T extends DropdownState = DropdownState>(
  reducer: DropdownReducer<T>,
  initialState: T
): [T, (action: string, value?: any) => void] {
  const [state, dispatch] = useReducer(reducer, initialState);

  const update = useCallback(
    (action: string, value: any = {}) => {
      dispatch({ action, ...value });
    },
    [dispatch]
  );

  // console.log('useOptionsState', { state });

  return [state, update];
}
