import React, {
  forwardRef,
  ReactNode,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  ModifierProps,
  useHandlersRef,
  useModifier,
  useStateRef,
} from '../hooks';
import { Icon, Icons } from '../icon';
import './styles.scss';

export interface ModalRefProps {
  visible: boolean;
  show: () => void;
  hide: () => void;
}

export interface ModalRefStateProps {
  visible: boolean;
  hidding?: any;
}

export interface ModalProps extends ModifierProps {
  visible?: boolean;
  showCloseButton?: boolean;
  children?: ReactNode;
  onClose?: () => void;
}

const Modal = forwardRef<ModalRefProps, ModalProps>(
  ({ children, onClose, ...props }, ref: Ref<ModalRefProps>) => {
    const hidding = useRef<any>(undefined);

    const [visible, { hide }] = useStateRef<boolean, ModalRefProps>(
      props.visible || false,
      (visible, setVisible) => ({
        visible,
        show: () => {
          if (!hidding.current) {
            setVisible(true);
          }
        },
        hide: () => {
          clearTimeout(hidding.current);
          setVisible(false);
          hidding.current = setTimeout(() => {
            hidding.current = undefined;
          }, 250);
        },
      }),
      ref
    );

    const modalCN = useModifier('modal', props.modifier, {
      'modal--visible': visible,
    });

    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (visible && elementRef.current && elementRef.current.focus) {
        const scrollTop = window.scrollY || window.pageYOffset;
        const scrollLeft = window.scrollX || window.pageXOffset;
        elementRef.current.focus({ preventScroll: true });

        setTimeout(() => window.scrollTo(scrollLeft, scrollTop), 1);
      }
    }, [visible]);

    const inside = useRef<any>(false);

    const handleDisableBlur = useCallback(() => {
      inside.current = true;
    }, []);

    const handleEnableBlur = useCallback(() => {
      inside.current = false;
    }, []);

    const handleBlur = useCallback(() => {
      if (visible && !inside.current) {
        hide();
        if (onClose) {
          onClose();
        }
      }
    }, [hide, visible]);

    return (
      <div
        ref={elementRef}
        tabIndex={visible ? 1 : 0}
        className={modalCN}
        onBlur={handleBlur}
        onMouseEnter={handleDisableBlur}
        onMouseOver={handleDisableBlur}
        onMouseLeave={handleEnableBlur}
      >
        <div className="modal__content">
          {children}
          {props.showCloseButton ? (
            <div className="modal__close-btn" onClick={hide}>
              <Icon icon={Icons.Close} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);

function useModalRef(ref: Ref<ModalRefProps>): ModalRefProps {
  return useHandlersRef<ModalRefProps>(
    useCallback(
      (modal) => ({
        visible: !!(modal && modal.visible),
        hide: () => {
          if (modal) {
            modal.hide();
          }
        },
        show: () => {
          if (modal) {
            modal.show();
          }
        },
      }),
      []
    ),
    ref as RefObject<ModalRefProps>
  );
}

export { Modal, useModalRef };
