import { Action, Context, Reducer } from '..';

export const reducerLogger = (reducer: Reducer): Reducer => {
  return (state: Context, action: Action) => {
    const log: any = {};
    log.actions = JSON.parse(JSON.stringify(action));
    log.before = JSON.parse(JSON.stringify(state));
    const after = reducer(state, action);
    log.after = JSON.parse(JSON.stringify(after));
    console.log('state', log);
    return after;
  };
};
