import {
  Ref,
  useState,
  useImperativeHandle,
  useMemo,
  RefObject,
  useEffect,
} from 'react';

export function useStateRef<State, Handlers>(
  state: State,
  handlers: (state: State, setState: (state: State) => void) => Handlers,
  ref: Ref<Handlers>
): [State, Handlers] {
  const [currentState, setState] = useState<State>(state);

  const stateHandlers = useMemo(() => handlers(currentState, setState), [
    currentState,
    handlers,
  ]);

  useImperativeHandle(ref, () => stateHandlers, [stateHandlers]);

  return [currentState, stateHandlers];
}

export function useHandlersRef<Handlers>(
  handlers: (ref: Handlers | null) => Handlers,
  ref: RefObject<Handlers>
) {
  const [handlerObj, setHandlersObj] = useState(handlers(ref.current));

  useEffect(() => {
    setHandlersObj(handlers(ref.current));
  }, [handlers, ref]);

  return handlerObj;
}
