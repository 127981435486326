import { useLayoutEffect, useState, useCallback, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useScrollListener = (target: HTMLElement | Window = window) => {
  const [scroll, setScroll] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    const handleScroll = (ev: any) => {
      const t: any = target;
      setScroll({
        x: t.scrollX || t.clientLeft,
        y: t.scrollY || t.clientTop,
      });
    };

    target.addEventListener('scroll', handleScroll);
    return target.removeEventListener('scroll', handleScroll);
  }, [target]);

  return [scroll];
};

function getScrollableParent(
  el: HTMLElement,
  scrollableParents: HTMLElement[] = []
): HTMLElement[] {
  if (el) {
    const parent = el.parentElement;
    if (parent) {
      if (
        parent.style.overflow === 'auto' ||
        parent.style.overflow === 'scroll' ||
        parent.style.overflowX === 'auto' ||
        parent.style.overflowX === 'scroll' ||
        parent.style.overflowY === 'auto' ||
        parent.style.overflowY === 'scroll' ||
        parent.scrollWidth > parent.clientWidth ||
        parent.scrollHeight > parent.clientHeight
      ) {
        scrollableParents.push(parent);
      }
      return getScrollableParent(parent, scrollableParents);
    }
  }
  return scrollableParents;
}

function addScrollListeners(
  scrollableEl: (HTMLElement | Window)[],
  handler: (event: any) => void
) {
  scrollableEl.forEach((el) => {
    el.addEventListener('scroll', handler);
  });
}

function removeScrollListeners(
  scrollableEl: (HTMLElement | Window)[],
  handler: (event: any) => void
) {
  scrollableEl.forEach((el) => {
    el.removeEventListener('scroll', handler);
  });
}

export interface BindPositionOptions {
  onActive?: (instance: HTMLElement) => void;
  onScroll?: (instance: HTMLElement, event: Event) => void;
  onResize?: (instance: HTMLElement, event: Event) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useBindPosition = (options?: BindPositionOptions) => {
  const [instance, setInstance] = useState<HTMLElement | null>(null);

  const handleActive = useCallback(
    (instance: HTMLElement | null) => {
      if (instance) {
        if (options && options.onActive) {
          options.onActive(instance);
        }
      }
    },
    [options]
  );

  useEffect(() => {
    if (instance) {
      const handleScroll = (event: Event) => {
        if (instance) {
          if (options && options.onScroll) {
            options.onScroll(instance, event);
          }
        }
      };

      const scrollableParents = [...getScrollableParent(instance), window];

      addScrollListeners(scrollableParents, handleScroll);
      return () => {
        removeScrollListeners(scrollableParents, handleScroll);
      };
    }
  }, [instance, options]);

  useEffect(() => {
    if (instance) {
      const handleResize = (event: Event) => {
        if (instance) {
          if (options && options.onResize) {
            options.onResize(instance, event);
          }
        }
      };

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [instance, options]);

  const positionRef = useCallback(
    (instance: HTMLElement | null) => {
      handleActive(instance);
      setInstance(instance);
    },
    [handleActive]
  );

  return [positionRef];
};

export { useScrollListener, useBindPosition };
