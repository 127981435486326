import React, { useCallback } from 'react';
import './styles.scss';
import { Button } from '../button';
import { Icon, Icons } from '../icon';

interface PaginatorBarProps {
  handleClickPage: (offset: number) => void;
  count: number;
  currentPage: number;
}

const MAX_ITEMS = 5;

const Pagination: React.FC<PaginatorBarProps> = (
  {
    handleClickPage,
    count,
    currentPage,
  }) => {


  const onClickPage = useCallback((page) => {
    handleClickPage(page);
  }, [handleClickPage]);


  const displayButtons = useCallback(() => {
    const results = [];
    results.push(
      <Button
        key={0}
        label={String(1)}
        onClick={() => onClickPage(0)}
        modifier={`transparent ${currentPage === 0 ? 'active' : ''}`}
      />);


    let initialPage = 1;
    let lastPage = 4;
    const lastIndexValid = count - 1;

    if (currentPage >= lastPage && count >= MAX_ITEMS) {
      initialPage = currentPage - 1;
      lastPage = Math.min(currentPage + 1, count - 2);
      results.push(<div key={count + 1} className="paginator-bar__content-more">...</div>);
    }

    if (lastIndexValid - 3 <= currentPage) {
      initialPage = (count - MAX_ITEMS);
    }

    if (count <= MAX_ITEMS) {
      initialPage = 1;
      lastPage = lastIndexValid;
    }

    for (let i = initialPage; i <= lastPage; i++) {
      results.push(
        <Button
          key={i}
          label={String(i + 1)}
          onClick={() => onClickPage(i)}
          modifier={`transparent ${currentPage === i ? 'active' : ''}`}
        />);
    }

    if (currentPage <= lastIndexValid - 3 && lastIndexValid - 3 > 0) {
      results.push(<div key={count + 2} className="paginator-bar__content-more">...</div>);
    }

    if (count > MAX_ITEMS) {
      results.push(<Button
        key={count - 1}
        label={String(count)}
        onClick={() => onClickPage(count - 1)}
        modifier={`transparent ${currentPage === (count - 1) ? 'active' : ''}`}
      />);
    }
    return results;
  }, [currentPage, onClickPage, count]);

  return (
    <div className="paginator-bar">
      <Button
        modifier="transparent paginator"
        label={<Icon icon={Icons.ChevronLeft} />}
        onClick={() => onClickPage(currentPage - 1)}
        disabled={currentPage === 0}
      />

      <div className="paginator-bar__content">
        {displayButtons()}
      </div>

      <Button
        modifier="transparent paginator"
        label={<Icon icon={Icons.ChevronRight} />}
        onClick={() => onClickPage(currentPage + 1)}
        disabled={count === (currentPage + 1)}
      />
    </div>
  );
};

export { Pagination };
